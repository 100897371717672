// extracted by mini-css-extract-plugin
export var arrowDown = "multiselect-module--arrow-down--vIMA6";
export var arrowDwn = "multiselect-module--arrow-dwn--nriQ2";
export var bounce = "multiselect-module--bounce--0cd6b";
export var checkbox = "multiselect-module--checkbox--kRuS4";
export var checked = "multiselect-module--checked--FBm+h";
export var close = "multiselect-module--close--kxaiU";
export var container = "multiselect-module--container--KRAXp";
export var item = "multiselect-module--item--IrtX7";
export var listItems = "multiselect-module--list-items--O7hNT";
export var noTagsText = "multiselect-module--no-tags-text--Hn0mI";
export var open = "multiselect-module--open--Gxjam";
export var path = "multiselect-module--path--SKYyS";
export var selectBtn = "multiselect-module--select-btn--Ctzpu";
export var tagName = "multiselect-module--tag-name--s6WGt";
export var text = "multiselect-module--text--9-AkK";
export var wrapper = "multiselect-module--wrapper--gEzsh";